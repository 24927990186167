:root {
    --color-yellow-primary: #febe10;
    --color-yellow-primary-o25: #febf1025;
    --color-yellow-secondary: #fdca46;
    --color-blue-primary: #142533;
    --color-blue-light: #f1f5f9;
    --color-gray-light: #f1f1f1;
    --color-gray-dark: #142533;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    height: 100%;
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

::selection {
    background-color: var(--color-yellow-primary);
    color: black;
}

.layout-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.page-wrap {
    flex: 1;
}

a {
    text-decoration: none !important;
}

q {
    background: var(--color-gray-light);
    font-style: italic;
    padding: 25px;
    border-radius: 25px;
}

.newsletter-btn {
    height: 38px;
}

.z-999999 {
    z-index: 999999;
}

.w-inherit {
    width: inherit;
}

.min-h-70 {
    min-height: 70px;
}

.max-w-750 {
    max-width: 750px;
}

.max-w-850 {
    max-width: 850px;
}

.max-w-1150 {
    max-width: 1150px !important;
}

.icone-size1-5 {
    font-size: 1.5rem;
}

.square {
    width: 50px;
    height: 50px;
}

.blog-render-rich-text a,
.link-color a,
.blog-article a {
    color: var(--color-yellow-primary);
}

.card-img-top {
    height: 220px;
    width: 100%;
}

.zoom:hover {
    transform: scale(1.01);
}

.home-banner {
    height: 500px;
    width: 100%;
    background-image: url("./assets/Home/home-banner.png");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top right;
    position: relative;
}

.home-banner::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.6;
}

.translate-middle-center-mob {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.w-100-to-lg-50 {
    width: 100% !important;
}

@media (min-width: 992px) {
    .h-lg-100 {
        height: 100%;
    }

    .w-100-to-lg-50 {
        width: 50% !important;
    }
}

@media (min-width: 768px) {
    .home-banner {
        height: 650px;
    }

    .translate-middle-center-mob {
        left: initial;
        transform: translate(0%, -50%);
    }
}

/* swiper */
.swiper-scrollbar-drag,
.swiper-pagination-bullet-active {
    background: var(--color-yellow-primary) !important;
}

.swiper-pagination {
    bottom: 0 !important;
}

.swiper-scrollbar {
    top: 0 !important;
}

/* swiper arrow */
.swiper-container {
    position: relative;
}

.swiper-button-next,
.swiper-button-prev {
    color: var(--color-yellow-primary) !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
    cursor: pointer;
    z-index: 10;
    margin-top: 0 !important;
}

.swiper-button-next {
    right: 0px !important;
}

.swiper-button-prev {
    left: 0px !important;
}

/* end swiper */

/* pagination */
.page-link {
    color: var(--color-blue-primary) !important;
}

.page-link:focus {
    box-shadow: 0 0 0 0.25rem rgba(20, 37, 51, 0.25) !important;
}

.active > .page-link {
    background-color: var(--color-blue-primary) !important;
    border-color: var(--color-blue-primary) !important;
    color: white !important;
}

.cursor-pointer {
    cursor: pointer;
}

.logo-container {
    height: 100%;
    max-width: 350px;
    object-fit: cover;
}
/* background */
.bg-gradient-yellow {
    background: linear-gradient(90deg, var(--color-yellow-primary) 0%, var(--color-yellow-secondary) 50%, var(--color-yellow-primary) 100%);
}

.bg-yellow {
    background: var(--color-yellow-primary) !important;
}

.bg-gray-light {
    background: var(--color-gray-light);
}
.bg-blue-primary {
    background: var(--color-blue-primary) !important;
}

.bg-blue-light {
    background-color: var(--color-blue-light);
}

/* color */
.color-primary-yellow {
    color: var(--color-yellow-primary);
}

.color-primary-yellow-hover {
    color: var(--color-blue-primary);
    transition: color 0.2s ease;
}

.color-primary-yellow-hover:hover {
    color: var(--color-yellow-primary);
}

.color-primary-blue {
    color: var(--color-blue-primary) !important;
}

.color-black {
    color: black;
}

/* border */
.border-bottom-yellow {
    max-width: 250px;
    border-bottom: 2px solid var(--color-yellow-primary);
}

.border-bottom-yellow-title {
    max-width: fit-content;
    border-bottom: 2px solid var(--color-yellow-primary);
}

@media (min-width: 992px) {
    .border-bottom-yellow {
        border-bottom: none;
    }

    .border-end-lg-yellow {
        border-right: 2px solid var(--color-yellow-primary);
    }
}

/* font-size */
.fs-7 {
    font-size: 0.875rem;
}
.fs-8 {
    font-size: 2.5rem;
}

.fs-10 {
    font-size: 0.75rem;
}

/* border radius */
.rounded-top-2 {
    border-top-left-radius: var(--bs-border-radius) !important;
    border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-2 {
    border-bottom-left-radius: var(--bs-border-radius) !important;
    border-bottom-right-radius: var(--bs-border-radius) !important;
}

/* form */
.form-check-input:checked {
    background-color: var(--color-yellow-primary) !important;
    border-color: var(--color-yellow-primary) !important;
}

.form-check-input:focus,
.form-control:focus {
    border-color: var(--color-yellow-primary) !important;
    box-shadow: 0 0 0 0.25rem var(--color-yellow-primary-o25) !important;
}

/* shadow */

.shadow-1 {
    box-shadow: 2px 1px 16px 1px rgba(0, 0, 0, 0.31);
    -webkit-box-shadow: 2px 1px 16px 1px rgba(0, 0, 0, 0.31);
    -moz-box-shadow: 2px 1px 16px 1px rgba(0, 0, 0, 0.31);
}

/* accordeon */
.accordion-button:not(.collapsed) {
    background: var(--color-blue-primary) !important;
    color: var(--color-yellow-primary) !important;
    text-transform: uppercase;
    font-weight: bold;
}

.accordion-button:focus {
    box-shadow: inset 0 0 0 2px var(--color-blue-primary) !important;
}

@media (min-width: 768px) {
    .padding-section {
        padding: 80px 0;
    }

    .fs-9 {
        font-size: 3.2rem;
    }
}

@media (min-width: 1400px) {
    .fs-9 {
        font-size: 4rem;
    }
}

/* Header  */
.nav-link,
.logo-container {
    position: relative;
    transition: all 0.3s ease;
    width: fit-content;
}

.burger-size {
    width: 20px;
    height: 20px;
}

.navbar-nav .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--color-blue-primary);
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 0px 0px 5px 5px;
    display: none;
}

.navbar-nav .dropdown-menu.show {
    display: block;
}

.navbar-nav .dropdown-item {
    color: white !important;
    background-color: var(--color-blue-primary) !important;
}

.navbar-nav .dropdown-item.active {
    color: var(--color-yellow-primary) !important;
}

.navbar-nav .dropdown-item:hover {
    color: var(--color-yellow-primary) !important;
}

@media (min-width: 768px) {
    .nav-link.active::before,
    .logo-container.active::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: rgb(255, 255, 255);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        bottom: 5px;
        left: 0;
    }

    .nav-link:hover::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: var(--color-yellow-primary);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        bottom: 5px;
        left: 0;
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0 !important;
        padding-left: 0 !important;
        margin-right: var(--bs-navbar-nav-link-padding-x);
        margin-left: var(--bs-navbar-nav-link-padding-x);
    }
}

/* ===== Components ===== */
/* CallToActionAndNewsletter */
.CallToActionAndNewsletter span {
    max-width: 80%;
}

/* ===== page ===== */
/* home */
.our-partners-logo-size {
    width: 200px;
    height: 75px;
    object-fit: scale-down;
}

@media (min-width: 768px) {
    .our-partners-logo-size {
        width: auto;
    }
}

@media (min-width: 992px) {
    .img-absolute {
        position: absolute;
        top: 50px;
        right: 40px;
    }

    .modal-dialog {
        max-width: 900px !important;
        top: 50%;
        transform: translate(0%, -50%) !important;
    }
}

/* Product page */
.position-badge-start-mobile {
    left: 15px;
}

@media (min-width: 768px) {
    .position-badge-start-mobile {
        left: auto;
    }
}

@media (min-width: 992px) {
    .more-space {
        margin-top: 150px !important;
    }
    .badge1 {
        top: -50px;
        left: -105px !important;
    }
    .badge2 {
        bottom: -135px;
        left: -105px !important;
    }
    .badge3 {
        bottom: -215px;
        left: 50% !important;
        transform: translateX(-50%);
    }
    .badge4 {
        bottom: -135px;
        right: -105px;
    }
    .badge5 {
        top: -50px;
        right: -105px;
    }
}

@media (min-width: 1440px) {
    .badge1 {
        top: -25px;
        left: -115px !important;
    }
    .badge2 {
        bottom: -120px;
        left: -115px !important;
    }
    .badge3 {
        bottom: -215px;
        left: 50% !important;
        transform: translateX(-50%);
    }
    .badge4 {
        bottom: -120px;
        right: -120px;
    }
    .badge5 {
        top: -25px;
        right: -120px;
    }
}

/* blog */

.card-blog-title {
    display: -webkit-box;
    line-clamp: 5;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-blog-text {
    display: -webkit-box;
    line-clamp: 5;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blog-content img {
    min-width: 50%;
    max-width: 100%;
}

.blog-content figure.image {
    text-align: center;
}

.blog-content p,
.blog-content h1,
.blog-content h2,
.blog-content h3,
.blog-content h4,
.blog-content h5,
.blog-content h6,
.blog-content div,
.blog-content div span,
.blog-content a,
.blog-content strong,
.blog-content figure,
.blog-content .layoutArea,
.blog-content p span {
    background-color: var(--color-blue-light) !important;
    margin: 0 !important;
    padding: 0px 0px 1.6em !important;
}

.blog-content p,
.blog-content div,
.blog-content div span,
.blog-content a,
.blog-content strong,
.blog-content figure,
.blog-content .layoutArea,
.blog-content p span {
    font-size: 18px !important;
}

/* contact */
.profile-img {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

/* team */
.line-bottom::after {
    content: "";
    position: absolute;
    width: 40%;
    height: 1px;
    background-color: var(--color-blue-primary);
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
}

.line-top::after {
    content: "";
    position: absolute;
    width: 40%;
    height: 1px;
    background-color: var(--color-blue-primary);
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
}
